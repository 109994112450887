import styles from "./Home.module.css";
import NameBox from "../components/Home/NameBox";
import HomeProjects from "../components/Home/HomeProjects";
import HomeProjectsMobile from "../components/Home/HomeProjectsMobile";

import { useRecoilValue } from "recoil";
import { isMobileState } from "../recoil_state";

const Home = () => {
  const isMobiles = useRecoilValue(isMobileState);

  return (
    <div className={styles.wrap}>
      <NameBox text={"FOCUS"} acc={15} />
      <NameBox text={"DESIGN"} acc={5} />
      {isMobiles ? <HomeProjectsMobile /> : <HomeProjects />}
    </div>
  );
};

export default Home;
