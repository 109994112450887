import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import styles from "./Navigation.module.css";
import Item from "./Navigation/Item";
import { Link, useHistory } from "react-router-dom";

// recoil
import { useRecoilValue, useRecoilState } from "recoil";
import { outLinkState, isMobileState } from "../recoil_state";
import { userState } from "../recoil_auth";

// firebase
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

const Navigation = () => {
  const [projects, setProjects] = useState([{}]);
  const outLink = useRecoilValue(outLinkState);

  // 로그인 인증
  const auth = useRecoilValue(userState);

  // 모바일 대응
  const [isMobile, setisMobile] = useRecoilState(isMobileState);

  const [isMenuHovered, setIsMenuHoverd] = useState(false);
  const [isProjectHovered, setIsProjectHoverd] = useState(false);
  const [isAboutHovered, setIsAboutHoverd] = useState(false);
  const [isContectHovered, setIsContectHoverd] = useState(false);
  const handleMouseOver = useCallback((target) => {
    switch (target) {
      case "menu":
        setIsMenuHoverd(true);
        break;
      case "project":
        setIsProjectHoverd(true);
        break;
      case "about":
        setIsAboutHoverd(true);
        break;
      case "contact":
        setIsContectHoverd(true);
        break;
      default:
        console.log("error: Invalid navigation key.");
        break;
    }
  }, []);
  const handleMouseOut = useCallback((target) => {
    switch (target) {
      case "menu":
        setIsMenuHoverd(false);
        break;
      case "project":
        setIsProjectHoverd(false);
        break;
      case "about":
        setIsAboutHoverd(false);
        break;
      case "contact":
        setIsContectHoverd(false);
        break;
      default:
        console.log("error: Invalid navigation key.");
        break;
    }
  }, []);
  const handleClick = useCallback(() => {
    setIsMenuHoverd(false);
    setIsProjectHoverd(false);
    setIsAboutHoverd(false);
    setIsContectHoverd(false);
    setIsOpen(false);
  }, []);

  const history = useHistory();
  // const projectClick = (key) => {
  //   handleClick();
  //   history.push("/project/temp");
  // };

  useEffect(() => {
    // 네비게이션에 프로젝트 리스트 출력을 위한 req
    const tempProjects = [];
    const colRef = collection(db, "project");
    getDocs(colRef)
      .then((snapshot) => {
        // const titles = snapshot.docs.map((doc) => doc.data().title);
        // const hashTag = snapshot.docs.map((doc) => doc.data().hashTag);
        // const id = snapshot.docs.map((doc) => doc.id);
        snapshot.docs.map((doc) => {
          tempProjects.push({
            id: doc.id,
            title: doc.data().title,
            hashTag: doc.data().hashTags[0],
          });
        });
        setProjects(tempProjects);
      })
      .catch((err) => {
        console.error("Error getting documents:", err);
      });

    // 모바일 여부 판단(width 768 <=) 후 recoil set
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setisMobile(true);
      } else {
        setisMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper className={styles.wrapper}>
      {isMobile ? (
        <div>
          <div className={styles.mNav}>
            <div className={styles.mHeader}>
              <span>Focus Design</span>
              {/* <div className={styles.mMenu}></div>
               */}
              <button
                className={`${styles.menuIcon} ${isOpen ? styles.open : ""}`}
                onClick={toggleMenu}
                aria-expanded={isOpen}
                aria-label="Toggle menu"
              >
                <span className={styles.bar}></span>
                <span className={styles.bar}></span>
                <span className={styles.bar}></span>
              </button>
            </div>
          </div>
          <div className={`${styles.mList} ${isOpen ? "" : styles.listOpen}`}>
            <NavTitle
              onMouseOver={() => handleMouseOver("menu")}
              onMouseOut={() => handleMouseOut("menu")}
              onClick={handleClick}
              className={styles.nav_contents}
            >
              <Link to={"/"}>
                <p>HOME</p>
              </Link>
            </NavTitle>
            <NavTitle
              onMouseOver={() => handleMouseOver("project")}
              onMouseOut={() => handleMouseOut("project")}
              onClick={handleClick}
              className={styles.nav_contents}
            >
              <Link to={"/projects"}>
                <p>Projects</p>
              </Link>
            </NavTitle>
            <NavTitle
              onMouseOver={() => handleMouseOver("about")}
              onMouseOut={() => handleMouseOut("about")}
              onClick={handleClick}
              className={styles.nav_contents}
            >
              <Link to={"/about"}>
                <p>About</p>
              </Link>
            </NavTitle>
            <NavTitle
              onMouseOver={() => handleMouseOver("contact")}
              onMouseOut={() => handleMouseOut("contact")}
              onClick={handleClick}
              className={styles.nav_contents}
            >
              <Link to={"/contact"}>
                <p>CONTACT</p>
              </Link>
            </NavTitle>
            <NavTitle
              onMouseOver={() => handleMouseOver("menu")}
              onMouseOut={() => handleMouseOut("menu")}
              onClick={handleClick}
              className={styles.nav_contents}
            >
              <a href={outLink.naver}>
                <p>NAVER BLOG↗</p>
              </a>
            </NavTitle>{" "}
            <NavTitle
              onMouseOver={() => handleMouseOver("menu")}
              onMouseOut={() => handleMouseOut("menu")}
              onClick={handleClick}
              className={styles.nav_contents}
            >
              <a href={outLink.instagra}>
                <p>INSTAGRAM↗</p>
              </a>
            </NavTitle>{" "}
            <NavTitle
              onMouseOver={() => handleMouseOver("menu")}
              onMouseOut={() => handleMouseOut("menu")}
              onClick={handleClick}
              className={styles.nav_contents}
            >
              <a href={outLink.naver}>
                <p>KAKAO Biz↗</p>
              </a>
            </NavTitle>
            {auth ? (
              <NavTitle
                onMouseOver={() => handleMouseOver("menu")}
                onMouseOut={() => handleMouseOut("menu")}
                onClick={handleClick}
                className={styles.nav_contents}
              >
                <Link to={"/admin"}>
                  <p>프로젝트 관리</p>
                </Link>
              </NavTitle>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <NavUl className={styles.nav_ul}>
          <NavLi key={"home"} className={styles.nav_li}>
            <NavTitle
              onMouseOver={() => handleMouseOver("menu")}
              onMouseOut={() => handleMouseOut("menu")}
              onClick={handleClick}
              className={`${styles.nav_title} ${
                isMenuHovered ? styles.nav_title_hover : ""
              }`}
              to={"/"}
            >
              <Link to={"/"}>
                <p>MENU</p>
              </Link>
            </NavTitle>
            <NavContents
              onMouseOver={() => handleMouseOver("menu")}
              onMouseOut={() => handleMouseOut("menu")}
              onClick={handleClick}
              className={`${styles.nav_contents} ${
                isMenuHovered ? styles.nav_contents_hover : ""
              }`}
            >
              <Item text={"HOME"} link={"/"} />
              <Item text={"PROJECT"} link={"/projects"} />
              <Item text={"ABOUT"} link={"/about"} />
              <Item text={"CONTECT"} link={"/contact"} />
              <Item text={"NAVER BLOG↗"} link={outLink.naver} state={false} />
              <Item
                text={"INSTAGRAM↗"}
                link={outLink.instagram}
                state={false}
              />
              <Item text={"KAKAO Biz↗"} link={outLink.kakaobiz} state={false} />
              {auth ? (
                <div>
                  {/* 메인페이지 노출 프로젝트 선택
                프로젝트 생성
                프로젝트 삭제
                */}
                  <Item
                    text={"프로젝트 관리"}
                    link={outLink.kakaobiz}
                    state={false}
                  />
                </div>
              ) : (
                ""
              )}
            </NavContents>
            {/* 확장 메뉴 영역 */}
          </NavLi>
          <NavLi key={"project"} className={styles.nav_li}>
            <NavTitle
              onMouseOver={() => handleMouseOver("project")}
              onMouseOut={() => handleMouseOut("project")}
              onClick={handleClick}
              className={`${styles.nav_title} ${
                isProjectHovered ? styles.nav_title_hover : ""
              }`}
            >
              <Link to={"/projects"}>
                <p>PROJECT</p>
              </Link>
            </NavTitle>
            <NavContents
              onMouseOver={() => handleMouseOver("project")}
              onMouseOut={() => handleMouseOut("project")}
              onClick={handleClick}
              className={`${styles.nav_contents} ${
                isProjectHovered ? styles.nav_contents_hover : ""
              }`}
            >
              {projects.map((project, index) => (
                <Item
                  key={index}
                  text={project.title}
                  link={`/project/${project.id}`}
                />
              ))}
            </NavContents>
            {/* 확장 메뉴 영역 */}
          </NavLi>
          <NavLi
            key={"about"}
            onMouseOver={() => handleMouseOver("about")}
            onMouseOut={() => handleMouseOut("about")}
            onClick={handleClick}
            className={`${styles.nav_li} ${
              isAboutHovered ? styled.nav_title_hover : ""
            }`}
          >
            <NavTitle
              onMouseOver={() => handleMouseOver("about")}
              onMouseOut={() => handleMouseOut("about")}
              onClick={handleClick}
              className={`${styles.nav_contents} ${
                isAboutHovered ? styled.nav_contents_hover : ""
              }`}
            >
              <Link to={"/about"}>
                <p>ABOUT</p>
              </Link>
            </NavTitle>
          </NavLi>
          <NavLi
            key={"contect"}
            onMouseOver={() => handleMouseOver("contact")}
            onMouseOut={() => handleMouseOut("contact")}
            onClick={handleClick}
            className={`${styles.nav_li} ${
              isContectHovered ? styled.nav_title_hover : ""
            }`}
          >
            <NavTitle
              onMouseOver={() => handleMouseOver("contact")}
              onMouseOut={() => handleMouseOut("contact")}
              onClick={handleClick}
              className={`${styles.nav_contents} ${
                isContectHovered ? styled.nav_contents_hover : ""
              }`}
            >
              <Link to={"/contact"}>
                <p>CONTACT</p>
              </Link>
            </NavTitle>
          </NavLi>
        </NavUl>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  position: fixed;
`;
const NavUl = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100vw;
  list-style-type: none;
`;
const NavLi = styled.li`
  width: 25%;
  font-family: "Oswald", sans-serif;
  overflow: hidden;
  font-size: 16px;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
`;
const NavTitle = styled.div`
  padding: 16px;
`;
const NavContents = styled.div`
  display: flex;
  flex-direction: column;
  visibility: hidden;
  width: 100%;
  height: 0vh;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
`;

export default Navigation;
